'use client'

import { createContext, useContext, PropsWithChildren, RefObject } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { useProductLayout } from './hooks'

export interface ProductUiContextType {
  mobileTopBarHeight: number
  setHeaderRefCallback: (ref: RefObject<HTMLDivElement> | null) => void
}

export const ProductUiContext = createContext<ProductUiContextType>({
  mobileTopBarHeight: 0,
  setHeaderRefCallback: () => {},
})

export const ProductUiContextProvider = ({ children }: PropsWithChildren) => {
  const { mobileTopBarHeight, setHeaderRefCallback } = useProductLayout()

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3 ?? ''}
    >
      <ProductUiContext.Provider
        value={{ mobileTopBarHeight, setHeaderRefCallback }}
      >
        {children}
      </ProductUiContext.Provider>
    </GoogleReCaptchaProvider>
  )
}

export const useProductUiContext = () => useContext(ProductUiContext)
