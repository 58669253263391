import { Domain, StoreCodeType } from '@/common/types'

const BASIC_DOMAINS = [
  Domain.COM,
  Domain.SK,
  Domain.CZ,
  Domain.HU,
  Domain.RO,
  Domain.DE,
  Domain.HR,
  Domain.BG,
  Domain.PL,
  Domain.SI,
  Domain.BA,
  Domain.RS,
  Domain.GR,
  Domain.IT,
  Domain.UA,
]

const STORE_CODES: {
  [key in Domain]: StoreCodeType
} = {
  [Domain.COM]: 'gymbeamcom',
  [Domain.SK]: 'gymbeamsk',
  [Domain.CZ]: 'gymbeamcz',
  [Domain.HU]: 'gymbeamhu',
  [Domain.RO]: 'gymbeamro',
  [Domain.DE]: 'gymbeamde',
  [Domain.HR]: 'gymbeamhr',
  [Domain.BG]: 'gymbeambg',
  [Domain.PL]: 'gymbeampl',
  [Domain.SI]: 'gymbeamsi',
  [Domain.BA]: 'gymbeamba',
  [Domain.RS]: 'gymbeamrs',
  [Domain.GR]: 'gymbeamgr',
  [Domain.IT]: 'gymbeamit',
  [Domain.UA]: 'ua',
  [Domain.RU]: 'ru',
}

/** gymbeamsk */
const DEFAULT_STORE_CODE: StoreCodeType = STORE_CODES[Domain.SK]

function getOriginAndLocale(url: string): {
  origin?: string
  locale?: Domain.UA | Domain.RU
} {
  try {
    const { origin, pathname } = new URL(url)
    const firstSegment = pathname?.split('/')[1]
    // Exception for UA - the first parameter is the selected locale
    let locale: Domain.UA | Domain.RU | undefined = undefined

    if (firstSegment === Domain.UA || firstSegment === Domain.RU) {
      locale = firstSegment
    }

    if (origin && origin !== 'null') {
      return { origin, locale }
    }
  } catch {}

  return {}
}

function getStoreCodeFromDomain(
  domain: Domain,
  locale?: Domain.UA | Domain.RU,
): StoreCodeType {
  // Exception for UA - the store code can be different based on locale
  if (locale && domain === Domain.UA) {
    return STORE_CODES[locale]
  }

  return STORE_CODES[domain] ?? DEFAULT_STORE_CODE
}

/**
 * Retrieves the store code based on the provided URL.
 *
 * - extract the origin and locale from the URL
 * - check the origin against different domain configurations (prod, next, staging24, qa24)
 * and returns the corresponding store code.
 * - fallback to {DEFAULT_STORE_CODE}
 * - handled exception for UA locales - the store code can be different based on locale
 *
 * @param url {string} The URL from which to extract the store code.
 * @returns {StoreCodeType} The store code based on the provided URL.
 */
export function getStoreCode(url: string): StoreCodeType {
  const { origin, locale } = getOriginAndLocale(url)

  if (!origin) return DEFAULT_STORE_CODE

  // prod
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://gymbeam.${domain}`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // next
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://${domain}.next.gymbeam.dev`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // staging24
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://${domain}.staging24.gymbeam.dev`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // qa24
  for (const domain of BASIC_DOMAINS) {
    if (origin === `https://${domain}.qa24.gymbeam.dev`) {
      return getStoreCodeFromDomain(domain, locale)
    }
  }

  // localhost & fallback
  return DEFAULT_STORE_CODE
}
