import { ProductStockStatus } from '@/api'
import { ConfigurableProductVariant } from '../product-data-item-types'

export const getInitialConfigurableProductVariant = (
  configurableVariants: Array<ConfigurableProductVariant> | null | undefined,
): ConfigurableProductVariant | undefined => {
  const productVariants =
    configurableVariants?.filter((variant) => variant?.product?.enabled) ?? []

  // Find and return bestselling product variant
  const bestseller = productVariants.find(
    (variant) =>
      variant?.isBestseller &&
      variant.product?.stockStatus === ProductStockStatus.InStock,
  )
  if (bestseller) {
    return bestseller
  }

  // Find and return first in stock variant
  const firstInStock = productVariants.find(
    (variant) => variant?.product?.stockStatus === ProductStockStatus.InStock,
  )
  if (firstInStock) {
    return firstInStock
  }

  // Find and return first enabled variant
  const firstEnabled = productVariants[0]
  if (firstEnabled) {
    return firstEnabled
  }

  // Find and return first existing variant
  return configurableVariants?.[0] ?? undefined
}
