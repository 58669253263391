import { FlashMessage } from '@/providers'

enum ExtensionErrors {
  NoSuchEntity = 'graphql-no-such-entity',
}

export type CartErrors = {
  extensions: {
    category: string
  }
  locations: {
    column: number
    line: number
  }[]
  message: string
  path: string[]
}

export const processAddToCartRawErrors = (
  rawErrors: string,
  customError?: string,
): FlashMessage[] => {
  let errors
  try {
    errors = JSON.parse(
      rawErrors.replace('Error: [{', '[{').replace('error: [{', '[{').trim(),
    )
  } catch (error) {
    console.error('Error parsing JSON:', error)
    errors = [{ severity: 'error', text: customError }]
  }

  return processAddToCartErrors(errors)
}

export const processAddToCartErrors = (
  errors: CartErrors[],
): FlashMessage[] => {
  return (errors ?? [])
    .filter(
      (error) =>
        !!error?.extensions?.category &&
        error.extensions.category !== 'internal',
    )
    .map(({ message }) => ({ severity: 'error', text: message }))
}

export function isNotCartExists(rawErrors: string): boolean {
  if (rawErrors) {
    const errors = JSON.parse(rawErrors)

    return (
      (errors ?? []).filter(
        (error) =>
          !!error?.extensions?.category &&
          error.extensions.category === ExtensionErrors.NoSuchEntity,
      ).length > 0
    )
  }
  return false
}
