'use client'

import { PropsWithChildren, useState } from 'react'
import { QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'

import { StoreCodeType, StoreConfigType } from '@/common/types'
import { queryClient as client } from '@/services'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { FlashMessagesProvider } from './flash-messages'
import { StoreContextProvider } from './store'

interface ProvidersProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
  cartId?: string
}

//page data context
export function Providers({
  children,
  storeCode,
  storeConfig,
  cartId,
}: PropsWithChildren<ProvidersProps>) {
  const [queryClient] = useState(() => client)

  return (
    <FlashMessagesProvider>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary>
          <AuthContextProvider>
            <CartContextProvider storeConfig={storeConfig} cartId={cartId}>
              <StoreContextProvider
                storeCode={storeCode}
                storeConfig={storeConfig}
              >
                {children}
              </StoreContextProvider>
            </CartContextProvider>
          </AuthContextProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </FlashMessagesProvider>
  )
}
