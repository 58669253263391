'use client'

import { createContext, useContext, PropsWithChildren } from 'react'

import { StoreCodeType, StoreConfigType } from '@/common/types'

export interface StoreContextType {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

export const StoreContext = createContext<StoreContextType>({
  storeCode: 'gymbeamsk',
} as StoreContextType)

interface DataContextProviderProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

export const StoreContextProvider = ({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<DataContextProviderProps>) => {
  return (
    <StoreContext.Provider value={{ storeCode, storeConfig }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
