import { getCookie, removeCookie } from '@/common/utils/cookie-utils'

const CUSTOMER_TOKEN = 'customerToken'

export const restoreCustomerToken = () => {
  const token = getCookie(CUSTOMER_TOKEN)

  return token
}

export const removeCustomerToken = () => {
  if (restoreCustomerToken()) {
    removeCookie(CUSTOMER_TOKEN)
  }
}
