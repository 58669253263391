'use client'

import { useState, useRef, useCallback, useEffect, RefObject } from 'react'

import { Breakpoints } from '@/common/hooks/use-is-browser-resolution'
import debounce from '@/common/utils/debounce'

const RESIZE_DELAY = 100

export const useProductLayout = () => {
  const [mobileTopBarHeight, setMobileTopBarHeight] = useState(0)
  const prevMobileTopBarHeight = useRef(0)
  const headerRef = useRef<HTMLDivElement | null>(null)

  const setHeaderRefCallback = useCallback((ref: RefObject<HTMLDivElement>) => {
    headerRef.current = ref.current
  }, [])

  useEffect(() => {
    const handleResizeDebounced = debounce(() => {
      const headerHeight = headerRef.current?.clientHeight ?? 0
      const mobileHeaderHeight =
        window.innerWidth < Breakpoints.Md ? headerHeight : 0

      if (prevMobileTopBarHeight.current !== mobileHeaderHeight) {
        prevMobileTopBarHeight.current = mobileHeaderHeight
        setMobileTopBarHeight(mobileHeaderHeight)
      }
    }, RESIZE_DELAY)

    handleResizeDebounced()

    window.addEventListener('resize', handleResizeDebounced)

    return () => {
      window.removeEventListener('resize', handleResizeDebounced)
    }
  }, [])

  return {
    mobileTopBarHeight,
    setHeaderRefCallback,
  }
}
