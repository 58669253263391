import { ReviewsFilterType } from '@/modules/product/components/tabs/components/reviews/filter/filter-types'
import {
  productQuestionPaginationFragment,
  productReviewPaginationFragment,
  SearchParams,
} from '../constants/url-constants'
import { SortingValues } from '@/modules/category/parts/filters/filters.const'

export type ProcessedUrlData = {
  pathname: string
  reviewCurrentPage: number
  questionsCurrentPage: number
  reviewPaginationUsed: boolean
  isReviewsCountSorting: boolean
  questionPaginationUsed: boolean
  reviewFilter: ReviewsFilterType
}

/**
 * function processes the URL, because in some cases, url includes additional information such as the active tab and the current page.
 * e.g.: /290-100-whey-gold-standard-protein-optimum-nutrition.html?review-page=3
 * @param requestUrl
 */
export const processRequestUrl = (requestUrl: string): ProcessedUrlData => {
  const url = new URL(requestUrl)

  const pathname = parsePathnameFromUrl(url)
  const searchParams = url.searchParams
  const reviewFilter: ReviewsFilterType =
    (searchParams.get('type') as ReviewsFilterType) ?? ReviewsFilterType.None
  const reviewPaginationUsed =
    !!searchParams.get(productReviewPaginationFragment) ||
    !!searchParams.get('type')
  const reviewCurrentPage = Number(
    searchParams.get(productReviewPaginationFragment) ?? '1',
  )
  const questionPaginationUsed = !!searchParams.get(
    productQuestionPaginationFragment,
  )
  const questionsCurrentPage = Number(
    searchParams.get(productQuestionPaginationFragment) ?? '1',
  )
  const isReviewsCountSorting =
    searchParams.get(SearchParams.CategorySorting) ===
    SortingValues.ReviewsCount

  return {
    pathname,
    reviewFilter,
    reviewCurrentPage,
    questionsCurrentPage,
    reviewPaginationUsed,
    isReviewsCountSorting,
    questionPaginationUsed,
  }
}

// TODO: unit test
export function parsePathnameFromUrl(url: URL): string {
  const rawPathname = url.pathname

  return rawPathname.substring(rawPathname.lastIndexOf('/'))
}
