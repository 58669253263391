import { ProductStockStatus } from '@/api'
import { ConfigurableProductVariant } from '../product-data-item-types'

export function getConfigurableProductVariantByHash(
  configurableVariants: Array<ConfigurableProductVariant> | null | undefined,
  hash: string,
) {
  const numberHash = +hash

  if (Number.isNaN(numberHash)) {
    return undefined
  }

  return configurableVariants?.find(
    (variant) =>
      variant?.product?.id === numberHash &&
      variant?.product?.enabled &&
      variant?.product?.stockStatus === ProductStockStatus.InStock,
  )
}
