import {
  useCustomerCartIdQuery,
  fetchGuestToken,
  GraphQlErrorsResponse,
} from '@/api'
import { queryClient } from '@/common/services'
import { removeCookie } from '@/common/utils/cookie-utils'
import { restoreCustomerToken } from '@/providers/auth/utils'
import { FlashMessage } from '@/providers/flash-messages'

export const fetchCustomerCartId = async () => {
  const response = await queryClient.fetchQuery({
    queryKey: useCustomerCartIdQuery.getKey(),
    queryFn: useCustomerCartIdQuery.fetcher(),
    retry: 3,
    retryDelay: 1000,
  })

  if (!response.customerCart || response.errors) {
    removeCookie('customerToken')
    removeCookie('customerSegment')
    return undefined
  }

  return response.customerCart.id
}

export const handleCartGraphqlError = async <T extends Record<string, unknown>>(
  data: T,
  handlerFn: (
    data: T & { cartId: string },
  ) => Promise<FlashMessage[] | undefined>,
) => {
  const response = await fetchGuestToken()

  if (response.error || !response.data) {
    console.error(response.error)
    throw new Error(JSON.stringify(response.error))
  } else {
    if (!restoreCustomerToken()) {
      return handlerFn({ ...data, cartId: response.data })
    } else {
      const customerCartId = await fetchCustomerCartId()
      const newCartId = customerCartId || response.data

      return handlerFn({ ...data, cartId: newCartId })
    }
  }
}

export const containsGraphqlErrorResponseSpecificCategory = (
  categories: string[],
  errs?: GraphQlErrorsResponse['errors'],
) => {
  return (
    errs &&
    errs?.some(
      (err) =>
        err.extensions?.category &&
        categories.includes(err.extensions.category),
    )
  )
}
