'use client'
import { useState, useEffect } from 'react'

const DefaultWindowSize = {
  width: undefined,
  height: undefined,
}

export enum BreakPointValues {
  Lg = 'Lg',
  Md = 'Md',
  Sm = 'Sm',
}

export const Breakpoints = {
  [BreakPointValues.Lg]: 1024,
  [BreakPointValues.Md]: 768,
  [BreakPointValues.Sm]: 640,
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number
    height?: number
  }>(DefaultWindowSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // TODO: Add throttling!!!
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

// TODO: replace it with `useIsBelowBreakpointBrowser` around the project
export const useIsBrowserMobile = (): boolean | undefined => {
  const { width: windowWidth } = useWindowSize()

  if (typeof windowWidth === 'number') {
    return windowWidth < Breakpoints.Md
  }

  return undefined
}

export const useIsBelowBreakpointBrowser = (value: BreakPointValues) => {
  const { width: windowWidth } = useWindowSize()

  return windowWidth === undefined ? false : windowWidth < Breakpoints[value]
}
